.float {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 13px;
  left: 15px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 123px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  transition: opacity 0.2s;
  opacity: 0.8;
}

.my-float {
  margin-top: 7px;
  margin-left: 2px;
}

.float:hover {
  opacity: 1;
  color: white !important;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.material-symbols-sharp {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 40;
}

.hidden {
  display: none;
}

.message {
  text-align: center;
  color: #f7f7f8;
  font-size: 2rem;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  20% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  40% {
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  90% {
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
}

@-moz-keyframes wiggle {
  0% {
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  20% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  40% {
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  90% {
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
}

@-o-keyframes wiggle {
  0% {
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  20% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  40% {
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  90% {
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes wiggle {
  0% {
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  20% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  40% {
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  90% {
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -ms-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6);
  }

  100% {
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -ms-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6);
  }

  100% {
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

@-o-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -ms-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6);
  }

  100% {
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -ms-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6);
  }

  100% {
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

#wpp-fix {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 12;
}

#wpp-fix .wpp-link {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #25d366;
  display: flex;
  z-index: 2;
  position: relative;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 3px 10px rgba(37, 211, 102, 0.56);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

#wpp-fix .wpp-link>span {
  display: none;
}

#wpp-fix .wpp-link:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  background: #1da851;
}

#wpp-fix .wpp-link.hide-this {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

#wpp-fix .wpp-link.hide-this+.form-wpp {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  border-radius: 1%;
}

#wpp-fix .wpp-link.hide-this+.form-wpp+.mask {
  opacity: 1;
  visibility: visible;
}

#wpp-fix .wpp-link+.form-wpp {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  bottom: 0;
  right: 0px;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  border-radius: 50%;
  z-index: 1;
  visibility: hidden;
  transform-origin: bottom right;
  width: 100%;
  max-width: 300px;
  min-width: 350px;
}

#wpp-fix .wpp-link+.form-wpp+.mask {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
}

#wpp-fix .wpp-link+.form-wpp .form-content {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 15px;
  float: left;
  position: relative;
  width: 100%;
  background-color: #e9e0d7;
  background-position: 50%;
  background-size: cover;
  background-image: url(https://www.davidsonsilva.com.br/wp-content/uploads/2023/04/bg-whatsapp.png);
}

#wpp-fix .wpp-link+.form-wpp .buss-msg {
  background-color: #fff;
  padding: 10px;
  border-radius: 0 4px 4px 4px;
  line-height: 1.3;
  font-size: 15px;
  max-width: 270px;
  margin: 0 0 10px;
  position: relative;
}

#wpp-fix .wpp-link+.form-wpp .buss-msg::before {
  content: "";
  position: absolute;
  border-top: 5px solid #fff;
  border-right: 5px solid #fff;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  left: -10px;
  top: 0;
}

#wpp-fix .wpp-link+.form-wpp form {
  width: calc(300px - 30px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  float: right;
  position: initial;
}

#wpp-fix .wpp-link+.form-wpp form label {
  line-height: 1;
  margin-bottom: 0px;
}

#wpp-fix .wpp-link+.form-wpp form label.error {
  font-size: 10px;
  padding: 0 5px;
  position: absolute;
  right: 0;
  bottom: 3px;
}

#wpp-fix .wpp-link+.form-wpp form .campo {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 5px;
}

#wpp-fix .wpp-link+.form-wpp form .form-control-wrap {
  float: left;
  width: 100%;
  position: relative;
}

#wpp-fix .wpp-link+.form-wpp form .form-control-wrap::after {
  content: "";
  position: absolute;
  border-top: 5px solid #e7ffe7;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #e7ffe7;
  right: -10px;
  top: 0;
}

#wpp-fix .wpp-link+.form-wpp form input[type=text],
#wpp-fix .wpp-link+.form-wpp form input[type=tel],
#wpp-fix .wpp-link+.form-wpp form input[type=email] {
  border-radius: 4px 0 4px 4px;
  width: 100%;
  padding: 10px;
  background: #e7ffe7;
  border: 1px solid #e7ffe7;
  font-size: 1rem;
}

#wpp-fix .wpp-link+.form-wpp form input.error,
#wpp-fix .wpp-link+.form-wpp form input.not-valid {
  border-color: #f44336;
}

#wpp-fix .wpp-link+.form-wpp form input+.not-valid-tip {
  font-size: 10px;
  padding: 0 5px;
  position: absolute;
  right: 0;
  bottom: 0;
}

#wpp-fix .wpp-link+.form-wpp form .btn {
  float: right;
  margin-top: 5px;
}

#wpp-fix .wpp-link+.form-wpp form .btn button {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #0c6156;
  border: 1px solid #0c6156;
  text-transform: initial;
  cursor: pointer;
  padding: 8px 12px;
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

#wpp-fix .wpp-link+.form-wpp form .btn button:hover {
  border-color: #06342e;
  background-color: #06342e;
  box-shadow: 0 1px 4px 0 rgba(12, 97, 86, 0.52);
}

#wpp-fix .wpp-link+.form-wpp form .response-output {
  font-size: 0.9rem;
  line-height: 1.2;
  float: left;
  margin: 0;
  width: 100%;
}

#wpp-fix .wpp-link+.form-wpp form::after {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 15px);
  background: rgba(255, 255, 255, 0.93);
  left: -2px;
  top: -9px;
  z-index: 5;
  background-image: url(https://www.davidsonsilva.com.br/wp-content/uploads/2023/04/load.gif);
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: 50%;
  opacity: 0;
  visibility: hidden;
}

#wpp-fix .wpp-link+.form-wpp form.submitting::after {
  opacity: 1;
  visibility: visible;
}

#wpp-fix .wpp-link+.form-wpp form .response-output {
  display: none;
  opacity: 0;
  @inclue transition();
}

#wpp-fix .wpp-link+.form-wpp form.sent {
  height: 50px;
}

#wpp-fix .wpp-link+.form-wpp form.sent .response-output {
  position: absolute;
  width: 100%;
  height: calc(100% + 15px);
  background: #fff;
  opacity: 1;
  left: 0;
  font-size: 1.4rem;
  text-align: center;
  top: 0;
  align-items: center;
  display: flex;
}

#wpp-fix .wpp-link+.form-wpp .head-wpp {
  width: 100%;
  padding: 12px 8px;
  background: #0c6156;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 6;
}

#wpp-fix .wpp-link+.form-wpp .head-wpp img {
  margin-right: 8px;
  width: 50px;
  border-radius: 50%;
}

#wpp-fix .wpp-link+.form-wpp .head-wpp h3 {
  word-break: keep-all;
  padding-right: 30px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  margin: 0 0 0 10px;
}

#wpp-fix .wpp-link+.form-wpp .close {
  color: #fff;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  padding: 7px 8px;
  font-size: 1.375rem;
}

#wpp-fix svg {
  width: initial;
  display: block;
  height: 30px;
}

#wpp-fix svg path {
  fill: #fff;
}

#wpp-fix.shake .wpp-link::before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(37, 211, 102, 0.6);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  top: 0px;
  right: 0;
  -webkit-animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite;
  -o-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

#wpp-fix.shake svg {
  -webkit-animation: 1s wiggle ease infinite;
  -moz-animation: 1s wiggle ease infinite;
  -o-animation: 1s wiggle ease infinite;
  animation: 1s wiggle ease infinite;
}

@media (max-width: 767px) {
  #wpp-fix {
    bottom: 15px;
  }

  #wpp-fix svg {
    height: 30px;
  }

  #wpp-fix .wpp-link {
    padding: 10px;
  }

  #wpp-fix .wpp-link+.form-wpp form {
    width: calc(300px - 40px);
  }

  #wpp-fix .wpp-link+.form-wpp form input[type=text],
  #wpp-fix .wpp-link+.form-wpp form input[type=email] {
    font-size: 0.875rem;
  }

  #wpp-fix .wpp-link+.form-wpp form .btn button {
    font-size: 0.875rem;
  }
}

@media (max-width: 395px) {
  #wpp-fix .wpp-link+.form-wpp {
    min-width: 290px;
  }

  #wpp-fix .wpp-link+.form-wpp .head-wpp img {
    width: 40px;
  }
}
.scrollTop {
    position: fixed;
    width: 40px;
    bottom: 20px;
    border-radius: 100%;
    align-items: center;
    background-color: white;
    left: 10px;
    height: 30px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.2s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}
body {
  overflow: visible !important;
  padding: 0 !important;
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 40px;
  border: none;
}

.swal2-container {
  z-index: 9999;
}
